import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import striptags from "striptags";
import "../../../node_modules/swiper/swiper.min.css";
import Link from "next/link";
import Image from "next/image";
import handleViewport from "react-in-viewport";
import {
  Navigation,
  Scrollbar,
  A11y,
  Controller,
  Thumbs,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { parseHtml } from "../../../utils/common";

const Services = (props) => {
  const { forwardedRef, inViewport } = props;
  const exquisiteData = props.exquisiteData;
  const [activeItem, setActiveItem] = useState(0);

  let slide = {
    modules: [Navigation, Controller, Scrollbar, A11y, Thumbs],
    loop: true,
    spaceBetween: 20,
    slidesPerView: 1,
    onSwiper: null,
    speed: 2000,
    autoplay: {
      delay: 2500,
      disableOnInteraction: true,
    },
    pagination: {
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-next",
      prevEl: ".swiper-prev",
    },
    breakpoints: {
      1920: {
        slidesPerView: 3,
      },
      991: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      767: {
        slidesPerView: 2,
      },
    },
  };

  const toggleAccordion = (index) => {
    if (activeItem === index) {
      setActiveItem(null);
    } else {
      setActiveItem(index);
    }
  };

  const titleWithoutPTag = exquisiteData.value.title;

  useEffect(() => {
    console.clear();
  }, []);
  return (
    <section ref={forwardedRef} className="our-services-section">
      {inViewport && (
        <div className="our-services-inner">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-12 col-md-12 col-sm-12">
                <div className="our-services-title">
                  <div className="section-heading">
                    <span
                      itemProp="heading"
                      dangerouslySetInnerHTML={{
                        __html: titleWithoutPTag || "",
                      }}
                    ></span>
                  </div>
                  {exquisiteData?.value?.service_list_page_chooser?.map(
                    (val, index) => {
                      return (
                        <div key={index} className="services-title-right">
                          <Link
                            href={val?.value?.page?.url}
                            itemProp="link"
                            className="link-font-lg"
                          >
                            {val?.value?.heading}
                          </Link>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
            <div className="lg-services-accordion">
              <div className="row">
                <div className="col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="row">
                    <div className="col-lg-6 col-md-7">
                      <div className="services-accordion">
                        <Accordion defaultActiveKey={0} flush>
                          {exquisiteData?.value?.list?.map((val, index) => {
                            return (
                              <Accordion.Item key={index} eventKey={index}>
                                <Accordion.Header
                                  onClick={() => toggleAccordion(index)}
                                >
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: val?.value?.title,
                                    }}
                                  ></span>
                                  <div className="accordion-read-more">
                                    <Link
                                      href={`/services/${val?.value?.page_block?.page?.slug}`}
                                      itemProp="link"
                                    >
                                      <Image
                                        src={val?.value?.page_block?.image?.url}
                                        alt={val?.value?.page_block?.image?.alt_text ?? 'read-more'}
                                        height={25}
                                        width={25}
                                        priority
                                        loading="eager"
                                        sizes="(max-width: 768px) 75vw, (max-width: 1200px) 40vw, 25vw"
                                        // layout="responsive"
                                        quality={50}
                                        style={{
                                          height: "auto",
                                          width: "auto",
                                        }}
                                      />
                                    </Link>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: val?.value?.description,
                                    }}
                                  ></p>
                                </Accordion.Body>
                              </Accordion.Item>
                            );
                          })}
                        </Accordion>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-5 align-self-middle">
                      <div
                        className="tab-content d-flex justify-content-center align-items-center h-100"
                        id="nav-tabContent"
                      >
                        {exquisiteData.value.list.map((item, itemIndex) => (
                          <div
                            key={itemIndex}
                            className={`tab-pane fade ${
                              itemIndex === activeItem ? "active show" : ""
                            }`}
                            id={`accordion-${itemIndex}`}
                          >
                            <div className="services-right-image">
                              <Image
                                width={250}
                                height={250}
                                src={item.value.image?.url}
                                alt={item.value.image?.alt_text ?? 'Service Image'}
                                priority={false}
                                loading="eager"
                                sizes="(max-width: 768px) 75vw, (max-width: 1200px) 40vw, 25vw"
                                quality={50}
                                style={{ width: "auto", height: "auto" }}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-services-accordion">
              <div className="mobile-services-accordion">
                <div className="swiper services-accordion-slider">
                  {/* <div className="swiper-wrapper"> */}
                  <Swiper {...slide} className="testimonials-slider">
                    {exquisiteData?.value?.list?.map((val, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="services-accordion-card">
                            <div className="services-accordion-details">
                              <h3
                                dangerouslySetInnerHTML={{
                                  __html: val?.value?.title,
                                }}
                              ></h3>
                              {parseHtml(val?.value?.description)}
                              <div className="services-read-more">
                                <Link
                                  href={`/services/${val?.value?.page_block?.page?.slug}`}
                                  itemProp="link"
                                >
                                  <Image
                                    height={25}
                                    width={25}
                                    src={val?.value?.page_block?.image?.url}
                                    alt={val?.value?.page_block?.image?.alt_text ?? 'read-more'}
                                    loading="eager"
                                  />
                                </Link>
                              </div>
                            </div>
                            <div className="services-accordion-img">
                              <Image
                                height={100}
                                width={100}
                                src={val?.value?.image?.url}
                                alt={val?.value?.image?.alt_text ?? 'Python Development Services'}
                                loading="eager"
                              />
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

const ServicesViewportBlock = handleViewport(Services);

export default ServicesViewportBlock;
